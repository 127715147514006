import React from 'react'
import skills from '../../data/skills.json';
import './skills.scss'
function Skills() {
  return (
    <div className='skills py-5 px-5 sm:!px-16 min-h-screen flex relative flex-col gap-16'>
        <h2 className="heading">
            The <br/>Skill Gallery
        </h2>
        <div className="skills__container flex flex-wrap justify-evenly items-center gap-10 sm:gap-20 md:gap-28 h-full">
            {skills.map((skill,i)=>{
                return <div className="skill" key={i}>
                    <div className="skill__image__container p-5 bg-[#ffffff10] rounded-full border-[#00000030] backdrop-blur-[20px]">
                    <img src={ `images/skills/${skill.icon}`} alt={skill.name} className="skill__image w-[40px] h-[40px]"/>
                    </div>
                    <h3 className="skill__name">{skill.name}
                    </h3>
                    </div>
            })}
        </div>
    </div>
  )
}

export default Skills