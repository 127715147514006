import React from "react";
import projectsData from "../../data/projects.json";
import ProjectCard from "../../utils/project-card/projectCard";
import "./projects.scss";
function Projects() {
  return (
    <div
      id="projects"
      className="projects py-5 px-5 sm:!px-16 min-h-screen flex relative flex-col gap-10"
    >
      <h2 className="heading">
        Grab a Coffee & <br />
        See the projects
      </h2>
      <div className="projects_container  justify-center">
        {projectsData.map((project, i) => {
          const projectData = {
            ...project,
            index: i,
          };
          return <ProjectCard key={i} {...projectData}></ProjectCard>;
        })}
      </div>
    </div>
  );
}

export default Projects;
