import React, { useState } from "react";
import Button from "../button/button";
import { ReactComponent as PopupIcon } from "../../assets/icons/popup.svg";
import { ReactComponent as TabIcon } from "../../assets/icons/newTab.svg";
import Modal from "../modal/modal";
import "./projectCard.scss";
import { useInView } from "react-intersection-observer";

interface IProjectAttributes {
  title: string;
  stack: string[];
  description: string;
  url: string;
  index: number;
}
function ProjectCard(props: IProjectAttributes) {
  const [closed, setClosed] = useState(true);
  const [ref, inView] = useInView({
    triggerOnce: false,
  });
  const [showHelp, setShowHelp] = useState(true);
  return (
    <>
      <div className="flex flex-col py-3 md:h-[20em] lg:h-[23em] justify-between lg:w-[50ch] lg:m-auto">
        <Modal
          url={props.url}
          title={props.title}
          closed={closed}
          setClosed={setClosed}
        ></Modal>
        <section>
          <h3 className="text-xl font-semibold">{props.title}</h3>
          <h4 className="text-secondary mb-5 text-lg font-light">
            {props.stack.join(", ")}
          </h4>
        </section>
        <section className="flex flex-col gap-5 justify-between h-full">
          <p className="mb-10 text-base ">{props.description}</p>
          <div className="flex gap-3">
            <span className="guide">
              {props.index === 0 ? (
                <span
                  ref={ref}
                  className={
                    "guide__text " + (inView && showHelp ? "show" : "")
                  }
                >
                  Click to view a demo right here.
                  <span
                    className="guide__close"
                    onClick={() => {
                      document
                        .querySelector(".guide__text")
                        ?.classList.remove("show");
                      setShowHelp(false);
                    }}
                  >
                    &times;
                  </span>
                </span>
              ) : (
                ""
              )}
              <Button
                onClick={() => {
                  setClosed(false);
                }}
              >
                Quick Demo&nbsp;
                <PopupIcon className="w-1/6 absolute opacity-0 sm:relative sm:opacity-100" />
              </Button>
            </span>
            <a href={props.url} target="_blank" rel="noreferrer">
              <Button type="normal">
              Open Live&nbsp;
                <TabIcon className="w-1/6 absolute opacity-0 sm:relative sm:opacity-100" />
              </Button>
            </a>
          </div>
        </section>
      </div>
    </>
  );
}

export default ProjectCard;
