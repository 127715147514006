import React, { useEffect } from "react";
import About from "../../components/about/about";
import Landing from "../../components/landing/landing";
import Projects from "../../components/projects/projects";
import Skills from "../../components/skills/skills";
import Timeline from "../../components/timeline/timeline";
import Recommendations from "../../components/recommendations/recommendations.component";
import { useLocation } from "react-router-dom";

export default function Home() {
  const location = useLocation();
  useEffect(() => {
    console.log(location);
    const { hash } = location;
    if (hash) {
      const element = document.querySelector(hash);
      element?.scrollIntoView();
    }
  }, [location]);
  return (
    <div className="2xl:container">
      <Landing />
      <About />
      <Recommendations />
      <Projects />
      <Skills />
      <Timeline />
    </div>
  );
}
