import React from 'react'
import './typingLoader.scss';

function TypingLoader({className}:{className?:string}) {
  return (
    <div className={`chat-bubble ${className}`}>
<div className="typing">
  <div className="dot"></div>
  <div className="dot"></div>
  <div className="dot"></div>
</div>
</div>
  )
}

export default TypingLoader