import React from "react";
import Button from "../../utils/button/button";
import "./nav.scss";
function Nav(props: any) {
  return (
    <nav className="nav">
      <div className="logo">
        <img src={`fav.svg`} alt="logo" className="logo w-[40px]  h-[40px]" />
      </div>
      <ul>
        <a href="#about">
          <li>About me</li>
        </a>
        <a href="#projects">
          <li>Projects</li>
        </a>
        <Button onClick={props.handleChatOpen}>Get in touch</Button>
      </ul>
    </nav>
  );
}

export default Nav;
