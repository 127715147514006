import React, { useEffect } from "react";
import Button from "../../utils/button/button";
import Nav from "../nav/nav";
import landingImage from "../../assets/images/landing4.png";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat.svg";
import Chat from "../chat/chat";
import landingData from "../../data/landing.json";
import "./landing.scss";
import Modal from "../../utils/modal/modal";
import ContactForm from "../contact-form/contact_form";

export default function Landing() {
  const [chatOpen, setChatOpen] = React.useState(false);
  const [clearChat, setClearChat] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const [modalClosed, setModalClosed] = React.useState(true);

  const handleChatOpen = (val: boolean) => {
    if (val) {
      setClearChat(val);
      setTimeout(() => {
        setChatOpen(val);
      }, 100);
    } else {
      setChatOpen(val);
      setTimeout(() => {
        setClearChat(val);
      }, 500);
    }
  };
  const handleModalOpen = () => {
    setModalClosed(false);
  };
  useEffect(() => {
    let showedGuide = false;
    const handleScroll = () => {
      const isMobile = window.innerWidth < 768;
      if (!showedGuide && window.scrollY > 100) {
        setShowHelp(true);
        showedGuide = true;
        setTimeout(() => {
          setShowHelp(false);
          if (isMobile) {
            window.removeEventListener("scroll", handleScroll);
          }
        }, 5000);
      }
      if (!isMobile && window.scrollY > document.body.scrollHeight - 1800) {
        handleChatOpen(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="landing py-5 px-2 sm:!px-16 sm:h-screen flex flex-col relative">
      <Modal
        title="Contact Me"
        closed={modalClosed}
        setClosed={setModalClosed}
        component={<ContactForm />}
      ></Modal>
      <Nav handleChatOpen={handleModalOpen} />
      <span className="flex flex-col-reverse h-full">
        <div className="landing__content lg:w-1/2 h-full flex flex-col justify-center items-center lg:items-start gap-10">
          <div className="flex flex-col items-center sm:items-start ">
            <span className=" text-lg sm:text-xl w-full block text-left">
              Hi,{" "}
              <span className="landing__content__name text-secondary text-lg sm:text-xl">
                I am
              </span>
            </span>
            <h2 className="landing__content__description  drop-shadow-sm text-4xl font-semibold">
              ARAVIND V V
            </h2>
            <h3 className="text-secondary text-2xl">
              {landingData.designation}
            </h3>
          </div>
          <div className="flex flex-col gap-5 sm:max-w-[75%]">
            {landingData.description.map((para, index) => (
              <p
                className="text-subtle sm:text-xl text-center md:text-left"
                key={index}
              >
                {para}
              </p>
            ))}
          </div>
          <span className="flex items-center justify-center lg:justify-start gap-3 w-full">
            <Button
              py="1"
              px="6"
              fsize="xl"
              onClick={() => {
                setModalClosed(false);
              }}
            >
              Hire Me
            </Button>
            <a
              href="mailto:arvndvv@gmail.com"
              className="text-secondary h-[35.2px] sm:h-full items-center flex px-3  bg-translucent backdrop-blur-[2px] border-secondary border-2 text-md"
            >
              Send a mail
            </a>
          </span>
        </div>
        <div className="landing__image relative lg:absolute right-0 bottom-0 w-[230px] h-[230px] mx-auto sm:h-full sm:w-[65%] -z-10  sm:overflow-visible ">
          <img
            src={landingImage}
            alt="landing"
            className="absolute sm:relative top-[30%] sm:top-0 h-full w-full object-cover"
          />
        </div>
      </span>
      <div className="chat__container">
        <span className="guide_chat">
          <span className={"guide_chat__text " + (showHelp ? "show" : "")}>
            Send a quick message to me.
            <span
              className="guide_chat__close"
              onClick={() => {
                document
                  .querySelector(".guide_chat__text")
                  ?.classList.remove("show");
                setShowHelp(false);
              }}
            >
              &times;
            </span>
          </span>
          <div
            className={`chat__open__icon ${
              chatOpen
                ? "opacity-0 pointer-events-none"
                : "delay-300 opacity-100"
            }`}
            onClick={() => {
              handleChatOpen(true);
            }}
          >
            <ChatIcon />
          </div>
        </span>

        {clearChat ? (
          <Chat chatOpen={chatOpen} setChatOpen={handleChatOpen} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
