import React from "react";
import Mychats from "../data/mychats.json";

interface IMatchAndReplaceObj {
    text:string,
    match:{
        link:string,
        text:string
    }[]
}
export enum EBotResponseTypes {
    questions = "questions",
    response = "response",
    intro = "intro",
    initial_greets = "initial_greets",
  ending_yes = "ending_yes",
  ending_no = "ending_no",
  }

export const matchAndReplace = (obj:IMatchAndReplaceObj, returnElement:string, className:string, anchorClassName?:string)=>{
    let convertedText = obj.text;
    obj.match.forEach((matchObj)=>{
        convertedText = convertedText.replace(matchObj.text,  `<a href="${matchObj.link}" rel="noreferrer" target="_blank" className="${anchorClassName}">${matchObj.text}</a>`)
    })
    const reactElement = React.createElement(returnElement, {dangerouslySetInnerHTML: {__html: convertedText},className})
    return reactElement;
}

export function wait(milliseconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }
  const getRandomFromArray = (array: string[]) => {
    return array[Math.floor(Math.random() * array.length)];
  };
  const getFirstMessage = () => {
    const message =
      getRandomFromArray(Mychats[EBotResponseTypes.initial_greets]) + " " + Mychats[EBotResponseTypes.intro];
    return message;
  };

  export const getMessagesArray = (index: number = 0, type=EBotResponseTypes.questions) => {
    const texts = [];
    if (index === 0 && type === EBotResponseTypes.questions) {
      texts.push(getFirstMessage());
    }
    if (index >= Mychats[type].length) {
      return texts;
    }
    const text = Mychats[type][index];

    texts.push(text);
    return texts;
  };

  export const uniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  }