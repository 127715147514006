import React, { Dispatch, SetStateAction } from "react";
import "./modal.scss";
interface props {
  url?: string;
  title?: string;
  closed?: boolean;
  component?: any;
  setClosed: Dispatch<SetStateAction<boolean>>;
}
function Modal({ url, title, closed, setClosed, component }: props) {
  return (
    <div
      className={`modal ${closed ? "closed" : ""} ${
        url ? "modal--url" : "modal--component"
      }`}
    >
      <div className="modal__header">
        <h5>{title}</h5>
        <span
          className="modal__close"
          onClick={() => setClosed((prev) => !prev)}
        >
          x
        </span>
      </div>
      {!closed &&
        (url ? (
          <iframe src={url} className="modal__view" title="project"></iframe>
        ) : (
          <div className="modal__view">{component}</div>
        ))}
    </div>
  );
}
// export constOpenDialog = () => {
//     setClosed(false)
// }
export default Modal;
