// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore,  collection, addDoc  } from "firebase/firestore";

import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAj9-YRDsiEnFU-wTsvBBDqhXzqjoidJv4",
  authDomain: "arvnd-69c49.firebaseapp.com",
  projectId: "arvnd-69c49",
  storageBucket: "arvnd-69c49.appspot.com",
  messagingSenderId: "150849519050",
  appId: "1:150849519050:web:305c17fce77e47fec0e333",
  measurementId: "G-JWGWV0RV7K"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
const auth = getAuth(app);
export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    const res = await signInWithPopup(auth, provider);
    const user = res;
    return user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
export const db = getFirestore(app);
export { collection, addDoc};