import React, { Dispatch, SetStateAction } from 'react'
interface IChatInputProps {
    placeholder: string,
    className?: string,
    disabled?: boolean,
    onInput: Dispatch<SetStateAction<string>>,
    inputValue : string,
    innerRef:any
}
function ChatInput({placeholder, className, disabled=false, onInput, inputValue='', innerRef}:IChatInputProps) {
  // const inputRef = useRef<HTMLInputElement>(null);
  
    function handleInput(e:any){
        onInput(e.target.value);
    }
  return (
    <input ref={innerRef} onInput={handleInput} value={inputValue} disabled={disabled} className={` bottom-0 w-full p-5 md:p3 text-slate-800 outline-none ${className} ${disabled ? 'opacity-50' : ''}`} type="text" placeholder={placeholder} autoFocus/>
  )
}

export default ChatInput