import React from "react";
import "./App.scss";
import { initializeAnalytics } from "firebase/analytics";

import Home from "./module/home";
import { app } from "./firebase/config";
import { BrowserRouter } from "react-router-dom";

function App() {
  initializeAnalytics(app);
  // console.clear()
  // console.log = ()=>{};
  // console.error = ()=>{};

  return (
    <BrowserRouter>
      <Home />
    </BrowserRouter>
  );
}

export default App;
