import React, {useState} from "react";
import recommendationsData from "../../data/recommendations.json";
import "./recommendations.styles.scss";
import Button from "../../utils/button/button";

export default function Recommendations() {
  const [expandedRecommendation, setExpanded] = useState<number[]>([]);

  const toggleExpand = (index: number) => {
    setExpanded(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };
  return (
    <div className="recommendations">
      <h2 className="heading">
        {recommendationsData.title}
      </h2>
      <div className="recommendations__container">
        {recommendationsData.data.map((recommendation, index) => (
          <div className="recommendation m-auto" data-index={index} key={index}>
            <blockquote
              cite="https://www.linkedin.com/in/arvndvv/details/recommendations/"
              className="recommendation__message"
            >
              <p className="font-delius">
                "{recommendation.msg.length > 300 && !expandedRecommendation.includes(index)
                  ? `${recommendation.msg.substring(0, 297)}...`
                  : recommendation.msg}"&nbsp;
              {recommendation.msg.length > 300 && (
                <span 
                className=" text-sm cursor-pointer"
                  onClick={() => toggleExpand(index)}
                >
                  {expandedRecommendation.includes(index) ? 'Show Less' : 'Read More'}
                </span>
              )}
              </p>
            </blockquote>
            <div className="recommendation__details">
              <div className="recommendation__author">
                <h4 className="recommendation__author__name">
                  {recommendation.name}
                </h4>
                <h6 className="recommendation__author__designation">
                  {recommendation.designation}
                </h6>
                <small className="recommendation__author__company">
                  <a
                    target="_blank"
                    href={recommendation.company.link}
                    rel="noreferrer"
                  >
                    {recommendation.company.name}
                  </a>
                </small>
              </div>
              <div className="recommendation__source">
                <a
                  href="https://www.linkedin.com/in/arvndvv/details/recommendations/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button type="normal">View</Button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
