import React from "react";
import aboutImage from "../../assets/images/about.png";
import aboutData from "../../data/about.json";

import "./about.scss";
function About() {
  return (
    <div
      id="about"
      className="about py-5 px-2 sm:!px-16 h-screen flex justify-end items-center relative overflow-hidden max-w-[100vw]"
    >
      <div className="about__container sm:bg-[#11111125] lg:bg-transparent  w-full  sm:backdrop-blur-md  sm:!border-2 lg:!border-none border-[#33333375] xl:border-none lg:w-1/2 p-3 items-center flex flex-col gap-12 ">
        <div className="about_head flex flex-col gap-3 lg:w-[70%] ">
          <h2 className="heading leading-[1.2]">
            {aboutData.title[0]}
            <br />
            {aboutData.title[1]}
          </h2>
          <h3 className=" text-ternary">{aboutData.subtitle}</h3>
        </div>
        <div className="about_description flex flex-col gap-5 md:w-[70%]">
          {aboutData.description.map((para, index) => (
            <p key={index}>{para}</p>
          ))}
          <ul className="links w-full sm:w-1/2    py-2 right-0 -bottom-1  flex gap-5">
            {aboutData.socials.map((social, index) => (
              <li key={index}>
                <a href={social.link} rel="noreferrer" target="_blank">
                  <img
                    src={social.img}
                    alt={social.alt}
                    className="h-10 w-10"
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="about__image absolute left-0  top-[5px] h-[120%] sm:h-full -z-10 opacity-30 sm:opacity-100">
        <img
          src={aboutImage}
          alt="landing"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default About;
