import React from "react";
import Button from "../../utils/button/button";

export default function ContactForm() {
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const toggleContactMessageForm = () => {
    setShowSuccess((prev) => !prev);
  };
  const handleMessage = async (e: any) => {
    e.preventDefault();
    const { name, email, message } = e.target.elements;
    const params = {
      name: name.value,
      email: email.value,
      message: message.value,
      site: "arvnd.in",
      _template: "table",
    };
    setLoading(true);
    await fetch("https://formsubmit.co/ajax/arvndvv@gmail.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    name.value = "";
    email.value = "";
    message.value = "";
    setLoading(false);
    toggleContactMessageForm();
  };
  return (
    <div className="w-full h-full  p-5 sm:p-10 relative">
      {showSuccess && (
        <div className="thankyou absolute w-full h-full bg-white left-0 top-0 z-10 flex justify-center items-center flex-col ">
          <h1 className="text-5xl font-lobster text-primary font-bold">
            Thank you
          </h1>
          <p className="text-center text-primary">
            your message has been delivered!
          </p>
          <span
            onClick={toggleContactMessageForm}
            className="select-none cursor-pointer absolute bottom-0 w-full p-[10px] bg-secondary text-white text-center hover:brightness-90"
          >
            Send Another Message
          </span>
        </div>
      )}
      <form className="flex  gap-5 flex-col" onSubmit={handleMessage}>
        <div className="flex flex-col sm:flex-row gap-5">
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-primary"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"
              placeholder="Thomas Shelby"
              required
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-primary"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5"
              placeholder="tommy@peakyblinders.com"
              required
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-primary "
          >
            Your message
          </label>
          <textarea
            id="message"
            name="message"
            rows={4}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
            placeholder="Write your message here..."
          ></textarea>
        </div>
        <div className="action">
          <Button disabled={loading}>Inform</Button>
        </div>
      </form>
    </div>
  );
}
