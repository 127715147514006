import React from 'react'
export enum EUserType {
    bot = 'Aravind',
    user = 'You'
}
export enum EMessageType {
    normal = 'normal',
    warning = 'warning',
    error = 'error',
    success = 'success'
}
interface IMessageProps {
    message: string,
    userType: EUserType,
    mtype?: EMessageType,
    hideFooter?: boolean
}

function Message({message,userType, mtype, hideFooter=false}:IMessageProps) {
    const date = (new Date());
    const time = `${date.getHours()}:${date.getMinutes()} ${date.getHours() > 12 ? 'PM' : 'AM'}`;
    const messageBg = {
        normal: 'bg-slate-300',
        warning: 'bg-yellow-300',
        error: 'bg-red-300',
        success: 'bg-green-300',
        'You': 'bg-blue-200'
    }
  return (
    <div className= {`chat__message flex flex-col gap-2 md:max-w-[65%] sm:!min-w-[50%] md:min-w-[25%] ${userType === EUserType.user ? 'md:ml-auto' : ''}`}>
    <div className={`chat__message__text text-primary bg-slate-300 rounded-md p-2 ${mtype ? messageBg[mtype] : userType === EUserType.user ? 'bg-blue-200' : ''}`}>{message}</div>
    {/* <div className='chat__message__image w-10 h-10 rounded-full bg-primary'></div> */}
    <div className='chat__message__content flex flex-col gap-1'>
        
        {!hideFooter ? (<div className='chat__message__content__header flex justify-between items-center px-1'>
            <h3 className='text-sm text-slate-500 font-bold'>{userType}</h3>
            <span className='text-xs text-subtle'>{time}</span>
            </div>): ''}
            
            
            </div></div>
  )
}

export default Message