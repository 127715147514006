import React from "react";
import "./button.scss";
interface IButtonProps {
  children: any;
  px?: string;
  py?: string;
  disabled?: boolean;
  fsize?: string;
  type?: "CTA" | "normal";
  key?: any;
  onClick?: () => void;
}
const Button = (props: IButtonProps) => {
  const { children, px, py, fsize, type, disabled } = props;
  return (
    <button
      onClick={props.onClick}
      disabled={disabled}
      className={`btn ${disabled && "opacity-50 pointer-events-none"} ${
        py ? "py-" + py : "py-0"
      } ${px ? "px-3 sm:px-" + px : "px-3 sm:px-4"} ${
        fsize ? "text-sm sm:text-" + fsize : "text-sm sm:text-lg"
      } ${type ? "btn--" + type : ""}`}
    >
      {children}
    </button>
  );
};

export default Button;
